import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { PostAdd } from '@material-ui/icons';
import { cfdsol1Color } from 'assets/jss/material-kit-react.js'
import CardBody from 'components/Card/CardBody';
import { cardTitle } from 'assets/jss/material-kit-react';
import CardHeader from 'components/Card/CardHeader';
import { cardSubtitle } from 'assets/jss/material-kit-react';
import { cardLink } from 'assets/jss/material-kit-react';
import Scroll from 'react-scroll'
import { scroller } from 'react-scroll'
import { Link, animateScroll as scroll } from "react-scroll";
import { primaryColor } from 'assets/jss/material-kit-react.js'

const ScrollLink = Scroll.ScrollLink
const Scroller = scroller.scrollTo

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  title: {
    color: cfdsol1Color,
    fontSize: 25,
  },
  subtitle: {
    color: "grey",
    fontsize: 15,
  },
  cardMedia: {
    width: 160,
    //height: 160,
  },
});

export default function FeaturedPost(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} md={6}>
      {/* <CardActionArea component="a" href={"#"+post.id}> */}
        <Card className={classes.card}>
          <CardBody>
            <cardTitle className={classes.title}>
              {post.title} <br />
            </cardTitle>
            <cardSubtitle className={classes.subtitle}>
              {post.date} 
            </cardSubtitle>
            <CardContent>
              {post.description}
            </CardContent>
            <Link
              // activeClass="active"
              to={post.id}
              spy={true}
              smooth={true}
              offset={-110}
              duration={500}
              style={{color:"#8a5f7f", cursor: "pointer", fontSize: "18px"}}
            >
              {post.linkText}
            </Link>
          </CardBody>
          {/* <div className={classes.cardDetails}>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {post.date}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {post.description}
              </Typography>
              <Typography variant="subtitle1" color="secondary">
                BLA
              </Typography>
            </CardContent>
          </div> */}
          <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden>
        </Card>
      {/* </CardActionArea> */}
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
