/* spell-checker: enable */
import React from "react";
// Helmet for setting title and meta name
import {Helmet} from 'react-helmet'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/profile_pic2_400x400.png";

import cfd_pic1 from "assets/img/Stirrer_Pic3_WhiteBG.png";
import cfd_pic2 from "assets/img/Stirrer_Pic4_WhiteBG.png";
import cfd_pic3 from "assets/img/Stirrer_Pic5_WhiteBG.png";

import studio1 from "assets/img/examples/studio-1.jpg";
import studio2 from "assets/img/examples/studio-2.jpg";
import studio3 from "assets/img/examples/studio-3.jpg";
import studio4 from "assets/img/examples/studio-4.jpg";
import studio5 from "assets/img/examples/studio-5.jpg";
import work1 from "assets/img/examples/olu-eletu.jpg";
import work2 from "assets/img/examples/clem-onojeghuo.jpg";
import work3 from "assets/img/examples/cynthia-del-rio.jpg";
import work4 from "assets/img/examples/mariya-georgieva.jpg";
import work5 from "assets/img/examples/clem-onojegaw.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Helmet>
        <title>Astrid Walle CFDsolutions - About Me</title>
        <meta name="description" content="Your CFD expert for fluid dynamics, aeromechanics, optimization and simulation data analysis" />
      </Helmet>
      <Header
        color="transparent"
        brand={<div>
          <img src={require("assets/img/favicon.png")} width={40} />
          <r>   Astrid Walle CFDsolutions </r>
          </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/BG1_CFD.png")} >
        <div className={classes.container_w}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title_w}>Your CFD Expert.</h1>
              <h4>
                My Professional Experience 
              </h4>
              <br />
            </GridItem>
          </GridContainer>     
        </div>
      </Parallax>    
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Dr.-Ing. Astrid Walle</h3>
                    <h6>Simulation & Data Analysis Enthusiast</h6>
                    {/* <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-twitter"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button> */}
                    <Button 
                      justIcon 
                      link
                      target="_blank"
                      href="https://github.com/astridwalle" 
                      color="transparent"
                      className={classes.margin5}
                    >
                      <i className={"fab fa-github"} />
                    </Button>
                    <Button 
                      justIcon 
                      link
                      target="_blank"
                      href="https://www.linkedin.com/company/astrid-walle-cfdsolutions/"
                      color="transparent" 
                      className={classes.margin5}
                    >
                      <i className={"fab fa-linkedin"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={2}>
                <img src={cfd_pic1} alt="..." className={navImageClasses}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.description}>
                  <p style={{textAlign:"left"}}>
                  I am a CFD freelancer based in Berlin, offering services ranging from multi physics simulation to optimization and data analysis.<br/>
                  <br/>
                  With more than a decade of experience in CFD applied to turbo machinery and chemical engineering, as well as multiple years of experience in data science, 
                  I can offer a wide pool of skills and tools to work on your simulation project.{" "} <br />
                  <br />
                  In my career I've always enjoyed working in international and interdisciplinary teams, whereby I'm used to remote work and communication via online conferencing tools.
                  </p>
                </div>
              </GridItem>
            </GridContainer> 
            <GridContainer justify="center" align="center" direction="row" alignItems="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.description}>
                  <p style={{textAlign:"left"}}>
                  In my professional career at Siemens Energy and Rolls Royce Germany as well as during my PhD in numerical methods at TU Darmstadt 
                  I have worked with various in-house and commercial CFD codes. Python and Bash programming has always been part of my day to day job. <br/>
                  <br/>
                  My technical competencies cover setting up automated process chains and optimizations, doing parametric design studies, surrogate modeling and sensitivity analyses.<br/>
                  <br/>
                  Also I combined CFD with data science, organizing simulation results in databases to optimise cluster usage and 
                  support post processing with image recognition.
                  One of the research topics I am actively following is physics-informed machine learning and I would love to support your research project in that field.
                  </p>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={2,2}>
                <img src={cfd_pic3} alt="..." className={navImageClasses}/>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={12} md={4}>
                <img src={cfd_pic2} alt="..." className={navImageClasses}/>
              </GridItem>
              <GridItem xs={12} sm={12} md={5}>
                <div className={classes.description}>
                  <p style={{textAlign:"left"}}>
                    In my different roles I successfully managed projects ranging from weeks to months and coordinated research programs with universities and research facilities. <br/>
                    <br/>
                    Communication across departments and with internal and external stakeholders has always been one of my strengths. 
                    The same applies to presenting complex content to audiences with varying background. 
                  </p>
                </div>
              </GridItem>
            </GridContainer> 


            {/* 
            to add:
            Berlin
            a passion for numerical simulation
            soft skills
            research

            */} 
            {/* <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Studio",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Work",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Favorite",
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
