import React from "react";
import {Helmet} from 'react-helmet'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/profile_pic_400x400.png";
import impressum from "assets/img/erecht24-schwarz-impressum-klein.png";
import datenschutz from "assets/img/erecht24-schwarz-datenschutz-klein.png";
//import profile from "assets/img/faces/christian.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Helmet>
        <title>Astrid Walle CFDsolutions - Impressum</title>
        <meta name="description" content="Your CFD expert for fluiddynamics, aeromechanics, optimization and simulation data analysis" />
      </Helmet>
      <Header
        color="transparent"
        brand={<div>
          <img src={require("assets/img/favicon.png")} width={40} />
          <r>   Astrid Walle CFDsolutions </r>
          </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/BG1_CFD.png")} >
        <div className={classes.container_w}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title_w}>Your CFD Expert.</h1>
              <h4>
                Legal Notice & Privacy Policy 
              </h4>
              <br />
            </GridItem>
          </GridContainer>     
        </div>
      </Parallax>           
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div>
            <br />
          </div>
          <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={2}>
                <img
                  alt="..."
                  src={impressum}
                  className={navImageClasses}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <img
                  alt="..."
                  src={datenschutz}
                  className={navImageClasses}
                />
              </GridItem>
            </GridContainer>
          </div>
          <div className={classes.description}>
            <h3 style={{textAlign:"left", color:"#8a5f7f", fontWeight:"bold"}}>Site Notice</h3>
            
            <h4 style={{textAlign:"left"}}>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h4>
            <p style={{textAlign:"left"}}>Astrid Walle<br />
            Astrid Walle CFDsolutions<br />
            Forbacher Straße 11<br />
            14169 Berlin</p>

            <h4 style={{textAlign:"left"}}>Contact</h4>
            <p style={{textAlign:"left"}}> Phone: 01771663428<br />
            E-mail: astridwalle@cfdsolutions.net</p>

            <h4 style={{textAlign:"left"}}>VAT ID</h4>
            <p style={{textAlign:"left"}}>Sales tax identification number according to Sect. 27 a of the Sales Tax Law:<br />
            DE332534243</p>

            <h4 style={{textAlign:"left"}}>Responsible for the content according to Sect. 55, paragraph 2 RStV</h4>
            <p style={{textAlign:"left"}}> Astrid Walle<br />
            Forbacher Straße 11<br />
            14169 Berlin</p>

            <h4 style={{textAlign:"left"}}>Dispute resolution proceedings in front of a consumer arbitration board</h4>
            <p style={{textAlign:"left"}}>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>

            <h4 style={{textAlign:"left"}}>Liability for Contents</h4> 
            <p style={{textAlign:"left"}}>As service providers, we are liable for own contents of these websites according to Paragraph 7, Sect. 1 German Telemedia Act (TMG). However, according to Paragraphs 8 to 10 German Telemedia Act (TMG), service providers are not obligated to permanently monitor submitted or stored information or to search for evidences that indicate illegal activities.</p> 
            <p style={{textAlign:"left"}}>Legal obligations to removing information or to blocking the use of information remain unchallenged. In this case, liability is only possible at the time of knowledge about a specific violation of law. Illegal contents will be removed immediately at the time we get knowledge of them.</p> 
            <h4 style={{textAlign:"left"}}>Liability for Links</h4> 
            <p style={{textAlign:"left"}}>Our offer includes links to external third-party websites. We have no influence on the contents of those websites, therefore we cannot guarantee for those contents. Providers or administrators of linked websites are always responsible for their own contents.</p> 
            <p style={{textAlign:"left"}}>The linked websites had been checked for possible violations of law at the time of the establishment of the link. Illegal contents were not detected at the time of the linking. A permanent monitoring of the contents of linked websites cannot be imposed without reasonable indications that there has been a violation of law. Illegal links will be removed immediately at the time we get knowledge of them.</p> 
            <h4 style={{textAlign:"left"}}>Copyright</h4> 
            <p style={{textAlign:"left"}}>Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing, distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the author or originator. Downloads and copies of these websites are permitted for private use only.<br /> The commercial use of our contents without permission of the originator is prohibited.</p> 
            <p style={{textAlign:"left"}}>Copyright laws of third parties are respected as long as the contents on these websites do not originate from the provider. Contributions of third parties on this site are indicated as such. However, if you notice any violations of copyright law, please inform us. Such contents will be removed immediately.</p>
          </div>
          <div className={classes.description}>
          <h3 style={{textAlign:"left", color:"#8a5f7f", fontWeight:"bold"}}>Privacy Policy</h3>
            <h4 style={{textAlign:"left"}}>1. An overview of data protection</h4>
            <h4 style={{textAlign:"left"}}>General information</h4> 
            <p style={{textAlign:"left"}}>The following information will provide you with an easy to navigate overview of what will happen with your personal data when you visit this website. The term “personal data” comprises all data that can be used to personally identify you. For detailed information about the subject matter of data protection, please consult our Data Protection Declaration, which we have included beneath this copy.</p>
            <h4 style={{textAlign:"left"}}>Data recording on this website</h4> 
            <h4 style={{textAlign:"left"}}>Who is the responsible party for the recording of data on this website (i.e. the “controller”)?</h4> 
            <p style={{textAlign:"left"}}>The data on this website is processed by the operator of the website, whose contact information is available under section “Information Required by Law” on this website.</p> 
            <h4 style={{textAlign:"left"}}>How do we record your data?</h4> <p style={{textAlign:"left"}}>We collect your data as a result of your sharing of your data with us. This may, for instance be information you enter into our contact form.</p> 
            <p style={{textAlign:"left"}}>Other data shall be recorded by our IT systems automatically or after you consent to its recording during your website visit. This data comprises primarily technical information (e.g. web browser, operating system or time the site was accessed). This information is recorded automatically when you access this website.</p> 
            <h4 style={{textAlign:"left"}}>What are the purposes we use your data for?</h4> 
            <p style={{textAlign:"left"}}>A portion of the information is generated to guarantee the error free provision of the website. Other data may be used to analyze your user patterns.</p> 
            <h4 style={{textAlign:"left"}}>What rights do you have as far as your information is concerned?</h4> 
            <p style={{textAlign:"left"}}>You have the right to receive information about the source, recipients and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a complaint with the competent supervising agency.</p> 
            <p style={{textAlign:"left"}}>Please do not hesitate to contact us at any time under the address disclosed in section “Information Required by Law” on this website if you have questions about this or any other data protection related issues.</p>
            <h4 style={{textAlign:"left"}}>Analysis tools and tools provided by third parties</h4> 
            <p style={{textAlign:"left"}}>There is a possibility that your browsing patterns will be statistically analyzed when your visit this website. Such analyses are performed primarily with what we refer to as analysis programs.</p> 
            <p style={{textAlign:"left"}}>For detailed information about these analysis programs please consult our Data Protection Declaration below.</p>
            <h4 style={{textAlign:"left"}}>2. General information and mandatory information</h4>
            <h4 style={{textAlign:"left"}}>Data protection</h4> 
            <p style={{textAlign:"left"}}>The operators of this website and its pages take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Data Protection Declaration.</p> 
            <p style={{textAlign:"left"}}>Whenever you use this website, a variety of personal information will be collected. Personal data comprises data that can be used to personally identify you. This Data Protection Declaration explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.</p> 
            <p style={{textAlign:"left"}}>We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third-party access.</p>
            <h4 style={{textAlign:"left"}}>Information about the responsible party (referred to as the “controller” in the GDPR)</h4> 
            <p style={{textAlign:"left"}}>The data processing controller on this website is:</p> 
            <p style={{textAlign:"left"}}>Astrid Walle<br />
            Forbacher Straße 11<br />
            14169 Berlin</p>

            <p style={{textAlign:"left"}}>Phone: 01771663428<br />
            E-mail: astridwalle@cfdsolutions.net</p>
            <p style={{textAlign:"left"}}>The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g. names, e-mail addresses, etc.).</p>

            <h4 style={{textAlign:"left"}}>Storage duration</h4> 
            <p style={{textAlign:"left"}}>Unless a more specific storage period has been specified in this privacy policy, your personal data will remain with us until the purpose for which it was collected no longer applies. If you assert a justified request for deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally permissible reasons for storing your personal data (e.g. tax or commercial law retention periods); in the latter case, the deletion will take place after these reasons cease to apply.</p>
            <h4 style={{textAlign:"left"}}>Information on data transfer to the USA</h4> 
            <p style={{textAlign:"left"}}>Our website uses, in particular, tools from companies based in the USA. When these tools are active, your personal information may be transferred to the US servers of these companies. We must point out that the USA is not a safe third country within the meaning of EU data protection law. US companies are required to release personal data to security authorities without you as the data subject being able to take legal action against this. The possibility cannot therefore be excluded that US authorities (e.g. secret services) may process, evaluate and permanently store your data on US servers for monitoring purposes.  We have no influence over these processing activities.</p>
            <h4 style={{textAlign:"left"}}>Revocation of your consent to the processing of data</h4> <p style={{textAlign:"left"}}>A wide range of data processing transactions are possible only subject to your express consent. You can also revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any data collection that occurred prior to your revocation.</p>
            <h4 style={{textAlign:"left"}}>Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)</h4> 
            <p style={{textAlign:"left"}}>IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6 SECT. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21 SECT. 1 GDPR).</p> 
            <p style={{textAlign:"left"}}>IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO AT ANY TIME OBJECT TO THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING. THIS ALSO APPLIES TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21 SECT. 2 GDPR).</p>
            <h4 style={{textAlign:"left"}}>Right to log a complaint with the competent supervisory agency</h4> 
            <p style={{textAlign:"left"}}>In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency, in particular in the member state where they usually maintain their domicile, place of work or at the place where the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative or court proceedings available as legal recourses.</p>
            <h4 style={{textAlign:"left"}}>Right to data portability</h4> 
            <p style={{textAlign:"left"}}>You have the right to demand that we hand over any data we automatically process on the basis of your consent or in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format. If you should demand the direct transfer of the data to another controller, this will be done only if it is technically feasible.</p>
            <h4 style={{textAlign:"left"}}>Information about, rectification and eradication of data</h4> 
            <p style={{textAlign:"left"}}>Within the scope of the applicable statutory provisions, you have the right to at any time demand information about your archived personal data, their source and recipients as well as the purpose of the processing of your data. You may also have a right to have your data rectified or eradicated. If you have questions about this subject matter or any other questions about personal data, please do not hesitate to contact us at any time at the address provided in section “Information Required by Law.”</p>
            <h4 style={{textAlign:"left"}}>Right to demand processing restrictions</h4> 
            <p style={{textAlign:"left"}}>You have the right to demand the imposition of restrictions as far as the processing of your personal data is concerned. To do so, you may contact us at any time at the address provided in section “Information Required by Law.” The right to demand restriction of processing applies in the following cases:</p> 
            <ul style={{textAlign:"left"}}> <li>In the event that you should dispute the correctness of your data archived by us, we will usually need some time to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we restrict the processing of your personal data.</li> 
            <li>If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand the restriction of the processing of your data in lieu of demanding the eradication of this data.</li> 
            <li>If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements, you have the right to demand the restriction of the processing of your personal data instead of its eradication.</li> 
            <li>If you have raised an objection pursuant to Art. 21 Sect. 1 GDPR, your rights and our rights will have to be weighed against each other. As long as it has not been determined whose interests prevail, you have the right to demand a restriction of the processing of your personal data.</li> </ul> 
            <p style={{textAlign:"left"}}>If you have restricted the processing of your personal data, these data – with the exception of their archiving – may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the rights of other natural persons or legal entities or for important public interest reasons cited by the European Union or a member state of the EU.</p>
            <h4 style={{textAlign:"left"}}>3. Analysis tools and advertising</h4>
            <h4 style={{textAlign:"left"}}>Google Analytics</h4> 
            <p style={{textAlign:"left"}}>This website uses functions of the web analysis service Google Analytics. The provider of this service is Google Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.</p> 
            <p style={{textAlign:"left"}}>Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end, the website operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized operating system and the user’s origin. Google may consolidate these data in a profile that is allocated to the respective user or the user’s device.</p> 
            <p style={{textAlign:"left"}}>Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user behavior patterns (e.g. cookies or device fingerprinting). The website use information recorded by Google is, as a rule transferred to a Google server in the United States, where it is stored.</p> 
            <p style={{textAlign:"left"}}>This analysis tool is used on the basis of Art. 6 Sect. 1 lit. f GDPR. The operator of this website has a legitimate interest in the analysis of user patterns to optimize both, the services offered online and the operator’s advertising activities. If a corresponding agreement has been requested (e.g. an agreement to the storage of cookies), the processing takes place exclusively on the basis of Art. 6 para. 1 lit. a GDPR; the agreement can be revoked at any time.</p> 
            <p style={{textAlign:"left"}}>Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details can be found here: <a href="https://privacy.google.com/businesses/controllerterms/mccs/" target="_blank" rel="noopener noreferrer">https://privacy.google.com/businesses/controllerterms/mccs/</a>.</p> <h4 style={{textAlign:"left"}}>IP anonymization</h4> <p style={{textAlign:"left"}}>On this website, we have activated the IP anonymization function. As a result, your IP address will be abbreviated by Google within the member states of the European Union or in other states that have ratified the Convention on the European Economic Area prior to its transmission to the United States. The full IP address will be transmitted to one of Google’s servers in the United States and abbreviated there only in exceptional cases. On behalf of the operator of this website, Google shall use this information to analyze your use of this website to generate reports on website activities and to render other services to the operator of this website that are related to the use of the website and the Internet. The IP address transmitted in conjunction with Google Analytics from your browser shall not be merged with other data in Google’s possession.</p>
            <h4 style={{textAlign:"left"}}>Browser plug-in</h4> 
            <p style={{textAlign:"left"}}>You can prevent the recording and processing of your data by Google by downloading and installing the browser plugin available under the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</p> 
            <p style={{textAlign:"left"}}>For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy Declaration at: <a href="https://support.google.com/analytics/answer/6004245?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>.</p>
            <h4 style={{textAlign:"left"}}>Contract data processing</h4> 
            <p style={{textAlign:"left"}}>We have executed a contract data processing agreement with Google and are implementing the stringent provisions of the German data protection agencies to the fullest when using Google Analytics.</p>
            <h4 style={{textAlign:"left"}}>Archiving period</h4> 
            <p style={{textAlign:"left"}}>Data on the user or incident level stored by Google linked to cookies, user IDs or advertising IDs (e.g. DoubleClick cookies, Android advertising ID) will be anonymized or deleted after 14 month. For details please click the following link: <a href="https://support.google.com/analytics/answer/7667196?hl=en" target="_blank" rel="noopener noreferrer">https://support.google.com/analytics/answer/7667196?hl=en</a></p>            
          </div>  
            {/* <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "Studio",
                      tabIcon: Camera,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio2}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={studio5}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio4}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Work",
                      tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work5}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    },
                    {
                      tabButton: "Favorite",
                      tabIcon: Favorite,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work4}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio3}
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src={work2}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={work1}
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src={studio1}
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer> */}
        </div>
      <Footer />
    </div>
  );
}
