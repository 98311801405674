import React from 'react';
// Helmet for setting title and meta name
import {Helmet} from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
import classNames from "classnames";
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header1 from 'components/Blog/Header.js';
import Header2 from 'components/Header/Header.js';
import HeaderLinks from 'components/Header/HeaderLinks.js';
import MainFeaturedPost from 'components/Blog/MainFeaturedPost';
import FeaturedPost from 'components/Blog/FeaturedPost';
import Main from 'components/Blog/Main';
import Markdown from 'components/Blog/Markdown'
import Sidebar from 'components/Blog/Sidebar';
import Footer from 'components/Footer/Footer.js';
// eslint-disable-next-line import/no-webpack-loader-syntax
import post1 from '!!raw-loader!components/Blog/blog-post.1.md';
import pic1 from "assets/img/dallas-reedy-F2HTC_CF4Jo-unsplash.jpg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import post2 from '!!raw-loader!components/Blog/StarCCM_Design_Manager.md';
import pic2 from "assets/img/leio-mclaren-leiomclaren-com-FwdZYz0yc9g-unsplash.jpg"
// eslint-disable-next-line import/no-webpack-loader-syntax
import post3 from '!!raw-loader!components/Blog/Blog_Tecplot_1.md';
// eslint-disable-next-line import/no-webpack-loader-syntax
import post4 from '!!raw-loader!components/Blog/Blog_Tecplot_2.md';
import pic4 from "assets/img/AWS_PC_Installation_SpeedUp_noNotes.png"
// eslint-disable-next-line import/no-webpack-loader-syntax
import post5 from '!!raw-loader!components/Blog/Blog_3D_interactive_contourplots.md';
import pic5 from "assets/img/3DContourplot.gif";
// eslint-disable-next-line import/no-webpack-loader-syntax
import post6 from '!!raw-loader!components/Blog/Blog_StreamlitApp.md';
import pic6 from "assets/img/CFD_Dashboard_Teaser_compressed.gif";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { LinkedIn, More } from '@material-ui/icons';

// const useStyles = makeStyles((theme) => ({
//   mainGrid: {
//     marginTop: theme.spacing(3),
//   },
// }));

const useStyles = makeStyles(styles)

// const sections = [
//   { title: 'Technology', url: '#' },
//   { title: 'Design', url: '#' },
//   { title: 'Culture', url: '#' },
//   { title: 'Business', url: '#' },
//   { title: 'Politics', url: '#' },
//   { title: 'Opinion', url: '#' },
//   { title: 'Science', url: '#' },
//   { title: 'Health', url: '#' },
//   { title: 'Style', url: '#' },
//   { title: 'Travel', url: '#' },
// ];

const mainFeaturedPost = {
  title: 'Title of a longer featured blog post',
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: 'https://source.unsplash.com/random',
  imgText: 'main image description',
  linkText: 'Continue reading…',
};

const posts = [
  {
    number: "1",
    id: "post1",
    item: post1,
    title: "How I got started on AWS",
    date:"2020-11-11",
    description: "Why I use AWS for my business",
    image: pic1,
    imageText: "Photo by Dallas Reedy on Unsplash",
    linkText: "Continue reading...",
  },
  {
    number: "2",
    id: "post2",
    item: post2,
    title: "Running StarCCM+ Design Manager on AWS parallelcluster",
    date:"2021-07-02",
    description: "Setting up StarCCM+ Design Manager, so you can submit lots of jobs in parallel to AWS parallelcluster",
    image: 'https://images.unsplash.com/photo-1541252260730-0412e8e2108e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=922&q=80',
    imageText: "Photo by Leio McLaren (leiomclaren.com) on Unsplash",
    linkText: "Continue reading..."
  },
  {
    number: "3",
    id: "post3",
    item: post3,
    title: "Postprocessing with Tecplot on AWS - Part 1",
    date: "2021-02-10",
    description: "Getting started with AWS ParallelCluster",
    image: 'https://s3.eu-west-1.amazonaws.com/cfdsolutions.net-pics/Tecplot_Blog.png',
    imageText: "ImageText",
    linkText: "Continue reading..."
  },
  {
    number: "4",
    id: "post4",
    item: post4,
    title: "Postprocessing with Tecplot on AWS - Part 2",
    date: "2021-04-13",
    description: "Saving costs & time",
    image: 'https://s3.eu-west-1.amazonaws.com/cfdsolutions.net-pics/Tecplot_Blog1.png',
    imageText: "ImageText",
    linkText: "Continue reading..."
  },
  {
    number: "5",
    id: "post5",
    item: post4,
    title: "Speed-Up AWS Parallelcluster launch",
    date: "2021-04-15",
    description: "How to speed up your AWS Parallelcluster launch by reusing your software installations",
    image: 'https://images.unsplash.com/photo-1590247813693-5541d1c609fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1984&q=80',
    imageText: "Pic by Nana Smirnova on Unsplash",
    linkText: "Continue reading..."
  },
  {
    number: "6",
    id: "post6",
    item: post5,
    title: "How to create interactive 3D CFD contourplots",
    date: "2021-04-15",
    description: "How to speed up your AWS Parallelcluster launch by reusing your software installations",
    image: pic5,
    imageText: "Interactive 3D contourplot",
    linkText: "Continue reading..."
  },
 {
    number: "7",
    id: "post7",
    item: post6,
    title: "Interactive CFD insights dashboard",
    date: "2021-04-15",
    description: "Generate insights and deep understanding by interactively exploring your CFD results",
    image: pic6,
    imageText: "Interactive 3D contourplot",
    linkText: "Continue reading..."
  },
]

{/*} which posts should be featured posts? */} 
//const featuredPosts = posts.slice(0,6);
const featuredPosts=[posts[1],posts[2],posts[3]]

// const featuredPosts = [
//   {
//     title: 'Featured post',
//     date: 'Nov 12',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random',
//     imageText: 'Image Text',
//     url: "http://www.google.com",
//     target: "_blank",
//     linkText: "Continue reading..."
//   },
//   {
//     title: 'Post title',
//     date: 'Nov 11',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random',
//     imageText: 'Image Text',
//     url: "http://www.google.com",
//     target: "_blank",
//     linkText: "Want to know more?"
//   },
// ];

// const posts = [post1, post2, post3];


const sidebar = {
  title: 'Welcome...',
  description:
    '... to my business blog where I share tips and best practices for CFD in the cloud.',
  // archives: [
  //   { title: 'March 2020', url: '#' },
  //   { title: 'February 2020', url: '#' },
  //   { title: 'January 2020', url: '#' },
  //   { title: 'November 1999', url: '#' },
  //   { title: 'October 1999', url: '#' },
  //   { title: 'September 1999', url: '#' },
  //   { title: 'August 1999', url: '#' },
  //   { title: 'July 1999', url: '#' },
  //   { title: 'June 1999', url: '#' },
  //   { title: 'May 1999', url: '#' },
  //   { title: 'April 1999', url: '#' },
  // ],
  social: [
    { name: 'GitHub', icon: GitHubIcon, href: "https://github.com/astridwalle" },
    { name: 'LinkedIn', icon: LinkedIn, href:  "https://www.linkedin.com/company/astrid-walle-cfdsolutions/"},
  ],
};

export default function Blog(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <div>
      {/* <Container maxWidth="lg"> */}
      <Helmet>
        <title>Astrid Walle CFDsolutions - Blog</title>
        <meta name="description" content="Your CFD expert for fluiddynamics, aeromechanics, optimization and simulation data analysis" />
      </Helmet>
        {/* <Header title="Blog" sections={sections} /> */}
        {/* <Header1 title="Blog"/> */}
        <Header2
          color="transparent"
          brand="Astrid Walle CFDsolutions"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/BG1_CFD.png")} >
          <div className={classes.container_w}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.title_w}>Your CFD Expert.</h1>
                <h4>
                  Blog - CFD in the Cloud
                </h4>
                <br />
              </GridItem>
            </GridContainer>     
          </div>
        </Parallax>  
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <main>
                {/* <MainFeaturedPost post={mainFeaturedPost} /> */}
                <Grid container spacing={5}>
                  {featuredPosts.map((post) => (
                    <FeaturedPost key={post.title} post={post} />
                  ))}
                </Grid>
                <Grid container spacing={5} className={classes.mainGrid}>
                 {/*} which posts should be displayed? */} 
                  <Main title="Posts" posts={featuredPosts} />
                  <Sidebar
                    title={sidebar.title}
                    description={sidebar.description}
                    archives={sidebar.archives}
                    social={sidebar.social}
                  />
                </Grid>
              </main>
              {/* <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h1 className={classes.title}>Your CFD Expert.</h1>
                  <h4>
                    Legal Notice & Privacy Policy 
                  </h4>
                  <br />
                </GridItem>
              </GridContainer>   */}
            </div>
          </div>
        </div>
        {/* <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={5}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="From the firehose" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main> */}
      {/* </Container> */}
      </div>
      <Footer  />
    </React.Fragment>
  );
}
