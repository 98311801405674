import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from 'components/Blog/Markdown';
import { Element } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
    color: "black"
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { posts, title } = props;

  return (
    <Grid item xs={12} md={8}>
      {/* <Typography variant="h6" gutterBottom>
        {title}
      </Typography> */}
      {posts.map((post) => (
        <Element id={post.id} name={post.id}>
          <Divider />
            <Markdown className={classes.markdown} key={post.item.substring(0, 40)}>
              {post.item}
            </Markdown>
        </Element>
      ))}
    </Grid>
  );
}

Main.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};
