import React from "react";
// Helmet for setting title and meta name
import {Helmet} from 'react-helmet'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Logo
import logo from "assets/img/favicon.png"

// Smooth Scrolling
import { Element } from 'react-scroll'

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import WorkSection from "./Sections/WorkSection.js";
import GetInTouchSection from "./Sections/GetInTouchSection.js";
import FocusAreasSection from "./Sections/FocusAreasSection.js";
import PrincipalSection from "./Sections/PrincipleSection.js"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Helmet>
        <title>Astrid Walle CFDsolutions</title>
        <meta name="description" content="Your CFD expert for fluiddynamics, aeromechanics, optimization and simulation data analysis" />
      </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<div>
          <img src={require("assets/img/favicon.png")} width={40} />
          <r>   Astrid Walle CFDsolutions </r>
          </div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/BG1_CFD.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={10}>
              <div className={classes.brand}>
                <h1 className={classes.title}>Your CFD & Data Science Expert.</h1>
                <h4>
                  Looking for support with your fluid dynamics or fluid structure interaction problem? < br /> 
                  Seeking for an optimization of your product geometry and operating conditions? < br /> 
                  I am happy to help. < br />
                  < br />
                  My mission: Bringing Data Science into engineering and CFD
                </h4>
              </div>
              <Button
                color="danger"
                size="lg"
                href="https://youtu.be/DSihumvkXgI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection /> 
          {/* <ServiceSection /> */}
          <FocusAreasSection />
          {/* <ExpectationSection /> */}
          <PrincipalSection />
          <GetInTouchSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
