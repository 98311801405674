import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Timer, AllInclusive, Assessment } from "@material-ui/icons";

// smooth scrolling
import { Element } from 'react-scroll'
import ScrollableAnchor from 'react-scrollable-anchor'


const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    //<Element id="MyServices" name="MyServices">
      <ScrollableAnchor id="MyServices">
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.title}>My Services.</h2>
              <h5 className={classes.description}>
                With more than a decade of professional experience in Computational Fluid Dynamics and Data Science, I can offer you a variety of services ranging from pure fluid to multi-physics fluid-structure interaction simulation and optimization. <br /> 
                Depending on your needs I can support with the execution of a specific work-package or deliver a complete solution.  
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Short-Term Peak Load"
                  description="You need to outsource a work-package on short notice due to a temporary shortage of resources? No problem, let's analyze the workload. With access to various software packages and unlimited compute power I might be able to help."
                  icon={Timer}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="End-To-End Solution"
                  description="You think CFD might improve your products? Let me help with creating a PoC and evaluating the potential for improvement, added value and feasibility. "
                  icon={AllInclusive}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Optimization & Data Science"
                  description="You want to optimize your product geometry and operating conditions or want to gain more insights on parameter sensitivities? Or do you want to add some data science, like surrogate modeling or image recognition supported postprocessing, on top of your simulation? Let's discuss your ideas."
                  icon={Assessment}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </ScrollableAnchor>
   // </Element>
  );
}
