import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Security from "@material-ui/icons/Security";
import Adjust from "@material-ui/icons/Adjust";
import Settings from "@material-ui/icons/Settings";
import Cloud from "@material-ui/icons/Cloud";
import Waves from "@material-ui/icons/Waves";
import Flag from "@material-ui/icons/Flag";
import ShowChart from "@material-ui/icons/ShowChart";
import GpsFixed from "@material-ui/icons/GpsFixed";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";

// smooth scrolling
import { Element } from 'react-scroll'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function PrincipleSection() {
  const classes = useStyles();
  return (
   // <Element id="MyPrinciples" name="MyPrinciples">
      <ScrollableAnchor id={"MyPrinciples"}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <h2 className={classes.title}>My Principles.</h2>
              <h5 className={classes.description}>
                I'm dedicated to provide customer focused service.
                So we will start with substantial discussions - in person or remote - to gain deep understanding of each other's expectations and to evaluate if my skills fit your needs.
                In the planning phase we will define goals and deliverables and specify as far as possible detailed work-packages.
                As I do apply agile methods and principles, you are able to participate and shape the outcomes of the project also during the implementation phase. Regular updates will help you to keep your stakeholders updated.
                During the closure phase, the agreed deliverables like simulation setups, results and documentation are handed over.
                Communication is everything, so if there is something missing or differs from expectations, just let me know and we will find a solution.
              </h5>
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Customized Solutions"
                  description="Whether you are looking for a multi-physics optimization including a parameter sensitivity study or a setup which you can reuse, let's tailor the perfect solution for your needs."
                  icon={Adjust}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Agility"
                  description="Applying the techniques of agile PM and service design principles, I will ensure that we don't miss the target."
                  icon={Settings}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Data Security"
                  description="From upload to download everything is protected with HTTPS/TLS in transit and server-side encryption at rest."
                  icon={Security}
                  iconColor="altrose"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </ScrollableAnchor>
  //  </Element>
  );
}
