/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

// smooth scrolling
import Scroll from 'react-scroll'
import { scroller } from 'react-scroll'
import { Link, animateScroll as scroll } from "react-scroll";
import Element from 'react-scroll'
import ScrollableAnchor from 'react-scrollable-anchor'

const ScrollLink = Scroll.ScrollLink
const Scroller = scroller.scrollTo

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        />
      </ListItem> */}  
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Home"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <a href="/#MyServices" className={classes.dropdownLink}>Services</a>,
            <a href="/#MyFocusAreas" className={classes.dropdownLink}>Focus Areas</a>,
            <a href="/#MyPrinciples" className={classes.dropdownLink}>Principles</a>,
            <a href="/#GetInTouch" className={classes.dropdownLink}>Contact</a>
            // <Link 
            // to="GetInTouch"
            // spy={true}
            // smooth={true}
            // offset={-110}
            // duration={500}   
            // className={classes.dropdownLink}   
            // >
            //   Contact
            // </Link>         
          ]}
        />
      </ListItem> 
      <ListItem className={classes.listItem}>
        {/* <Tooltip
            id="aboutme"
            title="My professional experience"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
        > */}
          <Button
            href="/about-me"
            color="transparent"
            target="_self"
            className={classes.navLink}
          >
            {/* <CloudDownload className={classes.icons} /> Download */}
            About Me
          </Button>
        {/* </Tooltip> */}
      </ListItem>
      <ListItem className={classes.listItem}>
          <Button
            href="https://demo.cfdsolutions.net"
            color="transparent"
            target="_self"
            className={classes.navLink}
          >
            Demo
          </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Button
            href="/blog"
            color="transparent"
            target="_self"
            className={classes.navLink}
          >
            Blog
          </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Button
            href="https://www.linkedin.com/company/astrid-walle-cfdsolutions/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"} />
          </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            href="https://github.com/astridwalle"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-github"} />
          </Button>
      </ListItem>
    </List>
  );
}
