import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Security from "@material-ui/icons/Security";
import Adjust from "@material-ui/icons/Adjust";
import Settings from "@material-ui/icons/Settings";
import Cloud from "@material-ui/icons/Cloud";
import Waves from "@material-ui/icons/Waves";
import Flag from "@material-ui/icons/Flag";
import ShowChart from "@material-ui/icons/ShowChart";
import GpsFixed from "@material-ui/icons/GpsFixed";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";

// smooth scrolling
import { Element } from 'react-scroll'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    //<Element id="MyFocusAreas" name="MyFocusAreas">
    <ScrollableAnchor id="MyFocusAreas">
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>My Focus Areas.</h2>
            <h5 className={classes.description}>
              Based on my professional experience with Siemens Energy (formerly Power and Gas) and Rolls-Royce as well as my PhD in numerical methods I focus on these specific topics.
            </h5>
          </GridItem>
        </GridContainer>
        <div> 
          <GridContainer justify="center" >
            <GridItem xs={12} sm={12} md={12} className={classes.navWrapper}>
              <NavPills
                alignCenter
                color="altrose"
                tabs={[
                  {
                    tabButton: "Fluid Dynamics",
                    tabIcon: Waves,
                    tabContent: (
                      <GridContainer justify="center" >
                        <GridItem xs={12} sm={12} md={10} >
                          <h5 className={classes.description}>
                            Fluid Dynamics Simulation <br />
                            - Compressible, Incompressible, Multiphase -<br />
                            Including creation of CAD model and mesh if needed
                          </h5>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Fluid Structure Interaction",
                    tabIcon: Flag,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={10}>
                          <h5 className={classes.description}>
                            Flutter and forced response analyses <br/>
                            Evaluation of fluid loads on structures
                          </h5>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Optimization",
                    tabIcon: GpsFixed,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={10}>
                          <h5 className={classes.description}>
                            Optimization of shape and operating conditions.
                          </h5>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Data Analysis",
                    tabIcon: ShowChart,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={10}>
                          <h5 className={classes.description}>
                            Parameter Sensitivity Analysis <br />
                            Make results visually accessible in dashboards <br />
                            Check out my <Button href="https://demo.cfdsolutions.net" Demo />
                          </h5>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Cloud Computing",
                    tabIcon: Cloud,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={10}>
                          <h5 className={classes.description}>
                            Consulting for moving your CFD HPC workloads into the cloud.
                          </h5>
                        </GridItem>
                      </GridContainer>
                    )
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </ScrollableAnchor>
  );
}
