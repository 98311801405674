import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// smooth scrolling
import { Element } from 'react-scroll'
import ScrollableAnchor from 'react-scrollable-anchor'

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  return (
    // <Element id="GetInTouch" name="GetInTouch">
    <ScrollableAnchor  id="GetInTouch">
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <h2 className={classes.title}>Get in Touch.</h2>
            <h4 className={classes.description}>
              Let's discuss solutions for your CFD case.<br/>
              Please get in touch for more information.<br/>
              <br/>
              <b>astridwalle@cfdsolutions.net</b><br/>
              <br/>
              <Button 
                color="altrose"
                href="mailto:astridwalle@cfdsolutions.net"
              >
                Send Message
              </Button>
            </h4>
          </GridItem>
        </GridContainer>
      </div>
    </ScrollableAnchor>
  );
}
